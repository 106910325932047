<script setup lang="ts">
import { useFluent } from 'fluent-vue'

const { $t } = useFluent()
const { sendSignInLinkToEmail, signInWithEmailLink } = useAuth()
const emit = defineEmits(['logged-in'])
// const router = useRouter()
const processing = ref(false)
const showTicket = ref(false)
const message = ref('')
const ticket = ref('')
const email = ref('')

const buttonTitle = computed(() => {
  return showTicket.value ? $t('action-sign_in') : $t('Email Me Verification Code')
})

watch(ticket, () => {
  // signInWithTicket(ticket)
})

async function sendRequest() {
  message.value = ''
  processing.value = true
  try {
    if (ticket.value) {
      await signInWithTicket()
    }
    else if (email.value) {
      await sendSigninEmail()
    }
  }
  catch (e) {
    message.value = e.message
  }
  processing.value = false
}
async function sendSigninEmail() {
  await sendSignInLinkToEmail({
    email: email.value,
    url: `${window.location.origin}/signin`,
  })
  ticket.value = ''
  window?.localStorage.setItem('signin-email', email.value)
  showTicket.value = true
}

async function signInWithTicket() {
  const emailStr = toValue(email) || window?.localStorage.getItem('signin-email')
  if (ticket.value && emailStr) {
    message.value = ''
    processing.value = true
    try {
      await signInWithEmailLink({ email: emailStr, ticket: ticket.value })
      window?.localStorage.removeItem('signin-email')
      emit('logged-in')
    }
    catch (e) {
      message.value = e.message
    }
    processing.value = false
  }
}
</script>

<template>
  <div class="email-sign-up-widget">
    <h3>{{ $t('action-sign_in') }}</h3>
    <div>
      <form ref="formEl" novalidate="true" @keydown.enter="sendRequest" @submit.prevent="sendRequest">
        <template v-if="!showTicket">
          <InputBox
            ref="inputEmail"
            v-model="email"
            class="centered"
            placeholder="Email address"
            label="Email address"
            type="email"
            name="email"
            autocomplete="email"
            no-spellcheck
            autofocus
            required
          />
        </template>
        <template v-if="showTicket">
          <h4>Enter the verification code sent to your email</h4>
          <InputBox
            ref="inputTicket"
            v-model="ticket"
            placeholder="1234567"
            class="code lg centered"
            type="text"
            name="ticket"
            autocomplete="off"
            no-spellcheck
            autofocus
            required
          />
        </template>
        <div v-if="message">
          {{ message }}
        </div>
        <div v-if="processing">
          <SpinnerIcon />
        </div>
        <button v-if="!processing" type="submit" class="solid">
          <span>{{ buttonTitle }}</span>
        </button>
      </form>
      <p v-if="!showTicket" class="text-balance">
        {{ $t('action-sign_in_desc') }}
      </p>
    </div>
    <p>
      By using our service you accept our <NuxtLink to="/terms">
        terms & conditions
      </NuxtLink>
    </p>
  </div>
</template>

<style lang="scss">
.email-sign-up-widget {
  text-align: center;
  padding: 0 var(--padding-base);

  .input-box,
  .input-box .input-wrap,
  button {
    margin-inline: auto;
  }

  button {
    position: relative;
    // background: transparent;
    overflow: hidden;
    // color: black;
    margin-block: var(--padding-base);

    // &::after {
    //   content: "";
    //   position: absolute;
    //   inset: 0;
    //   background-color: white;
    //   mix-blend-mode: difference;
    //   z-index: 1;
    // }

    &:hover,
    &:focus {
      &::after {
        inset: 0;
        width: 100%;
        height: 100%;
        transform: none;
      }
    }
  }
}
</style>
