<script setup lang="ts">
function toggleDialog(isOpen: boolean) {
  isSigninDialogOpen.value = isOpen
}
</script>

<template>
  <button @click="toggleDialog(!isSigninDialogOpen)">
    <span>{{ $t('action-sign_in') }}</span>
    <UiPopoverWidget v-if="isSigninDialogOpen" :open="isSigninDialogOpen" @request-close="toggleDialog(false)">
      <template #header-title />
      <AuthEmailSignupWidget @logged-in="toggleDialog(false)" />
    </UiPopoverWidget>
  </button>
</template>
